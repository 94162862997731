import { render, staticRenderFns } from "./ClienteNotas.vue?vue&type=template&id=60c7c11f&"
import script from "./ClienteNotas.vue?vue&type=script&lang=js&"
export * from "./ClienteNotas.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';
installComponents(component, {VCard,VCardText,VCardTitle,VIcon,VSlideXReverseTransition,VSpacer,VTimeline,VTimelineItem})
