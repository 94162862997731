<template>
  <v-card class="pa-6">
    <template v-if="!loading && eventos.length">
      <v-card flat v-for="(evento, index) in eventos" :key="index" class="mb-3">
        <v-card-title class="px-3 py-1 white--text text-subtitle-2 toolbar">
          <v-icon color="white" left>{{ evento.icone }}</v-icon>
          {{ evento.tipoevento }}
          <v-spacer></v-spacer>
          {{ evento.created_at | dateFormat("dd/MM/yyyy") }}
        </v-card-title>
        <v-card-title class="px-3 pt-0 pb-2 white--text text-caption toolbar">
          {{ evento.descricao }}
        </v-card-title>
        <v-card-text class="py-0" v-if="evento.notas.length">
          <v-timeline dense>
            <v-slide-x-reverse-transition group hide-on-leave>
              <v-timeline-item
                v-for="(nota, index) in evento.notas"
                :key="index"
                color="lightgreen"
                small
              >
                <v-card flat color="tabs" class="px-3 py-2">
                  <v-card-title class="pa-0 mb-1 text-body-2">
                    {{ nota.usuario }}
                  </v-card-title>
                  <v-card-text class="pa-0 text-caption text-justify">
                    {{ nota.nota }}
                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </v-slide-x-reverse-transition>
          </v-timeline>
        </v-card-text>
        <v-card-text v-else class="pa-3 lightgray">
          Evento sem notas!
        </v-card-text>
      </v-card>
    </template>
    <div v-else-if="loading" class="pa-6">
      <Carregando />
    </div>
    <div v-else-if="!eventos.length" class="pa-6">
      Sem eventos para esse cliente ate o momento.
    </div>
  </v-card>
</template>

<script>
import { fetchEventosCustomedio } from "@/services/api/eventos.api.js";

export default {
  name: "ClienteNotas",

  props: {
    cliente_id: {
      type: [Number, String],
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      eventos: [],
    };
  },

  methods: {
    getEventos() {
      this.loading = true;
      fetchEventosCustomedio(this.cliente_id)
        .then((response) => {
          this.eventos = response;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },

  mounted() {
    this.getEventos();
  },
};
</script>

<style></style>
